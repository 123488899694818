<div class="dialog">
    <div class="dialog_header">
        <button [disabled]="loadingSubmit" (click)="openConfirmDialog()" class="close"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{"company_register.Apply as a company"|translate}}
    </div>
    <div class="dialog_content" mat-dialog-content>
        <div class="step_index">
            <span>{{CurrentStep+1}}</span> {{"companies.from"|translate}} {{TotalSteps}}
        </div>

        <mat-progress-bar mode="determinate" [value]="((CurrentStep+1)/TotalSteps)*100"></mat-progress-bar>

        <mat-stepper linear #stepper (selectedIndexChange)="onSelectChange($event)">
            <mat-step [stepControl]="getFormGroup('companyName')">
                <form [formGroup]="getFormGroup('companyName')">
                    <div class="title"> {{"company_register.Basic data of the company"|translate}}</div>

                    <div class="fields-group mb-3">
                        <app-image-control [label]="'company_register.Company or organization logo' | translate"
                            [managerIndex]="'profile_image'" [ratio]="1 / 1" [maxSize]="2"
                            (imageReady)="OnSelectProfileImage($event)">
                        </app-image-control>

                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="arabic-form-field">
                            <mat-label>اسم الشركة (باللغة العربية)</mat-label>
                            <input matInput maxlength="100" formControlName="name_ar">
                            <mat-error *ngIf="hasError('companyName','name_ar','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('companyName','name_ar', 'pattern')">
                                {{ 'forms.Enter name (in Arabic)' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="english-form-field">
                            <mat-label>Company's name (in English)</mat-label>
                            <input matInput maxlength="100" formControlName="name_en">
                            <mat-error *ngIf="hasError('companyName','name_en','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('companyName','name_en', 'pattern')">
                                {{ 'forms.Enter name (in English)' |translate }}</mat-error>
                        </mat-form-field>
                    </div>

                </form>
            </mat-step>
            <mat-step [stepControl]="getFormGroup('company_info')">
                <form [formGroup]="getFormGroup('company_info')">
                    <div class="title">{{"companies.Company Address"|translate}}</div>
                    <div class="fields-group mb-3">
                        <div class="ng-select-field">
                            <ng-select ngSelectMat appearance="fill"
                                [class.ng-invalid]="hasError('company_info','city_id','required')"
                                formControlName="city_id" [items]="CitesList" [virtualScroll]="true"
                                [loading]="loadingCities" bindLabel="name" bindValue="id"
                                [placeholder]='"companies.The City" | translate'
                                [notFoundText]='"general.No Data Found" | translate'
                                [loadingText]='"forms.Loading..." | translate' (search)="dropSearch($event)"
                                (clear)="OnClear()" (blur)="OnClear()" [editableSearchTerm]="true" (remove)="OnClear()"
                                dropdownPosition="bottom" [clearable]="true" (close)="OnClear()"
                                (change)="citesModal.search=''; dropSearch($event)">
                                <!-- <ng-template ng-header-tmp *ngIf="CitesList?.length && !loadingCities">
                                    <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                        {{CitesList.length}} {{'forms.of' | translate}} {{citiesCount}}</small>
                                </ng-template> -->
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-clear="clear">
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <mat-error class="has-error" *ngIf="hasError('company_info','city_id','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </div>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="arabic-form-field">
                            <mat-label>العنوان التفصيلي (باللغة العربية)</mat-label>
                            <textarea matInput rows="5" maxlength="100" formControlName="address_ar"></textarea>
                            <mat-hint>{{"general.optionalAR"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('company_info','address_ar', 'pattern')">
                                {{ 'forms.Enter address (in Arabic)' |translate}}</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="english-form-field">
                            <mat-label>Street Address (in English)</mat-label>
                            <textarea matInput rows="5" maxlength="100" formControlName="address_en"></textarea>
                            <mat-hint>{{"general.optionalEn"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('company_info','address_en', 'pattern')">
                                {{ 'forms.Enter address (in English)' | translate}}</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="title">{{"companies.Contact Person"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="phoneNumberleft">
                            <mat-label>{{ "general.Mobile Number" | translate }}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="10" minlength="8"
                                formControlName="company_phone_number">
                            <span matSuffix>+966 </span>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('company_info','company_phone_number','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"companies.Email"|translate}}</mat-label>
                            <input matInput type="email" maxlength="100" formControlName="company_email">
                            <mat-error *ngIf="hasError('company_info','company_email','pattern')">
                                {{"form_message.Invalid email"|translate}}
                            </mat-error>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>

                        </mat-form-field>
                    </div>


                </form>
            </mat-step>

            <mat-step [stepControl]="getFormGroup('company_package')">
                <form [formGroup]="getFormGroup('company_package')">
                    <div class="title">
                        {{"companies.Select the Package (Suscribtion Plan)"|translate}}
                    </div>
                    <div class="fields-group mb-5" *ngIf="!loadingPackages">
                        <mat-radio-group aria-label="Select an option" class="row cards packages"
                            formControlName="package_id">

                            <div class="col-6 pb-2" *ngFor="let package of PackagesList">
                                <mat-radio-button class="w-100" [value]="package.id" matRipple
                                    matRippleColor="#00000012">
                                    <img src="/assets/images/icons/splash.svg" class="cover_bg" alt="select cover"
                                        srcset="">

                                    <p class="price" *ngIf="ToNumber(package.price)">
                                        {{package.price|number}} <span
                                            class="currency">{{"general.SR"|translate}}</span>
                                    </p>
                                    <p class="price" *ngIf="!ToNumber(package.price)">
                                        {{"companies.free"|translate}}
                                    </p>
                                    <p class="name">{{package.name_ar}}</p>
                                    <p class="name">{{package.name_en|titlecase}}</p>
                                    <p class="deuration" *ngIf="package.type=='year'">
                                        {{"companies.for year"|translate: {num:package.duration} }} </p>
                                    <p class="deuration" *ngIf="package.type=='month'">
                                        {{"companies.for month"|translate: {num:package.duration} }}</p>

                                    <p class="duration mt-2">
                                        <span class="grey-color font-16">{{package?.max_employee_number|number}}</span>
                                        {{"companies.Employee"|translate}}
                                    </p>
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                        <div class="has-error mt-2" *ngIf="hasError('company_package','package_id','required') ">
                            {{"form_message.Required"|translate}}
                        </div>

                    </div>

                    <div *ngIf='loadingPackages' class="dataLoading py-5 col-12">
                        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                    </div>


                </form>
            </mat-step>

            <mat-step [stepControl]="getFormGroup('company_files')">
                <form [formGroup]="getFormGroup('company_files')">
                    <div class="title">{{"companies.Commercial and tax registration number"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"companies.Commercial registration No."|translate}}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="20" formControlName="license_number">
                            <mat-error *ngIf="hasError('company_files','license_number','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <app-file-manager [maxFiles]="1" [allowedExt]="['jpg', 'png','jpeg','pdf']" [maxFileSize]="2"
                            (selectedFiles)="OnSelectLicense_attachment($event)" managerIndex="license_attachment"
                            [label]="'companies.cerificate of Commercial registry document'|translate"></app-file-manager>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"companies.Tax Number"|translate}}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="20" formControlName="tax_number">
                            <mat-error *ngIf="hasError('company_files','tax_number','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <app-file-manager [maxFiles]="1" [allowedExt]="['jpg', 'png','jpeg','pdf']" [maxFileSize]="2"
                            (selectedFiles)="OnSelectTax_attachment($event)" managerIndex="tax_attachment"
                            [label]="'companies.Registrartion Document of added VAT System'|translate"></app-file-manager>
                    </div>


                </form>
            </mat-step>

            <mat-step [stepControl]="getFormGroup('company_admin')">
                <form [formGroup]="getFormGroup('company_admin')" id="comapny_form">



                    <div class="title">{{"companies.Company Admin Data"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"companies.Full Name"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="full_name">
                            <mat-error *ngIf="hasError('company_admin','full_name','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label> {{"companies.Position"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="job_title">
                            <mat-error *ngIf="hasError('company_admin','job_title','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="fields-group mb-3">
                        <mat-form-field class="phoneNumberleft">
                            <mat-label> {{"companies.Mobile Number"|translate}}</mat-label>
                            <input matInput type="text" maxlength="8" minlength="8" appOnlynumber
                                formControlName="phone_number">
                            <span matSuffix>+966 <b>5</b></span>
                            <mat-error *ngIf="hasError('company_admin','phone_number','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('company_admin','phone_number','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label> {{"companies.Email"|translate}}</mat-label>
                            <input matInput type="email" maxlength="100" formControlName="email">
                            <mat-error *ngIf="hasError('company_admin','email','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('company_admin','email','pattern')">
                                {{"form_message.Invalid email"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>



                </form>


            </mat-step>

        </mat-stepper>

    </div>
    <div class="dialog_actions justify-content-between" mat-dialog-actions>
        <button mat-button [disabled]="loadingSubmit" (click)="openConfirmDialog()" *ngIf="CurrentStep==0"
            class="btn-secondary">{{"general.Cancel"|translate}}</button>
        <button mat-button (click)="stepper.previous()" *ngIf="CurrentStep!=0" class="btn-secondary"
            [disabled]="loadingSubmit">{{"companies.back"|translate}}</button>
        <button mat-button (click)="GoNext()" *ngIf="CurrentStep!=4" class="btn-primary" type="submit">
            {{"companies.next"|translate}}
        </button>
        <button mat-button *ngIf="CurrentStep==4" (click)="OnSubmit()" [disabled]="loadingSubmit"
            [class.loading]="loadingSubmit" class="btn-primary" form="comapny_form" type="submit">
            {{"companies.submit application"|translate}}
        </button>
    </div>
</div>